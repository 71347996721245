<template>
  <div class="dark-bg fullheight">
    <div class="split50">
      <div style="height: 12vh">
        <div class="center-img pt-3">
          <img src="/resources/images/skygruppen.png" />
        </div>
        <h3 class="text-light" style="text-align: center">VI BYGGER FREMTIDENS TALENTER OG LEDERE</h3>
      </div>
      <div class="split15">
      <div class="row">
          <div class="company-logo col-md-4 p-0">
            <img src="/resources/images/skycall-dark.png"/>
          </div>
          <div class="company-logo col-md-4 p-0">
            <img src="/resources/images/skyfacility-dark.png" />
          </div>
          <div class="company-logo col-md-4 p-0">
            <img src="/resources/images/skyestate-dark.png" />
          </div>
          <div class="company-logo col-md-4 p-0">
            <img src="/resources/images/skymarketing-dark.png" />
          </div>
          <div class="company-logo col-md-4 p-0">
            <img src="/resources/images/skyfinance-dark.png" />
          </div>
          <div class="company-logo col-md-4 p-0">
            <img src="/resources/images/peur-dark.png" style="padding: 25px"/>
          </div>
      </div>
      <div class="row" style="max-height: 23vh">
      <div class="col-md-12 mt3" v-if="loaded && hasPosts" style="height: calc(23vh - 1rem); overflow: hidden;">
      <div class="col-xl-12 col-lg-12">
        <div class="card dark-card" v-for="post in posts" :key="post.id">
          <div class="card-body">
            <div class="mb-2">
              <a
                href="javascript: void(0);"
                class="text-light font-size-24 font-weight-bold"
              >{{ post.title }}</a>
            </div>
            <div class="mb-3">
              <a class="font-weight-bold text-light" href="javascript: void(0);">{{ post.author.name }}</a> skrev {{ moment(post.date).fromNow() }}
            </div>
            <div class="mb-4">
              <a
                href="javascript: void(0);"
                class="badge text-blue text-uppercase bg-light font-size-12 mr-2"
                v-for="(tag, i) in post.tags"
                :key="i"
              >{{ tag }}</a>
            </div>
            <div v-if="post.featured_media">
              <div class="col-lg-3">
                <img :src="post.featured_media" alt="">
              </div>
              <div
                v-html="post.content"
                class="col-lg-9 text-light"
              />
            </div>
            <div
              v-html="post.content"
            />
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    <div class="split50">
      <Views />
    </div>
  </div>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import moment from 'moment'
import Views from '@/views/TV/views'

export default {
  components: {
    Views,
  },
  data() {
    return {
      posts: [],
      loaded: false,
      hasPosts: false,
      timer: '',
    }
  },
  methods: {
    moment,
    getRecentPosts() {
      const ref = firestore.collection('news').where('status', '==', 'published').orderBy('date', 'desc')
      ref.onSnapshot(snapshot => {
        this.posts = []
        snapshot.forEach(doc => {
          this.posts.push(doc.data())
        })
        this.hasPosts = Boolean(this.posts.length)
        this.loaded = true
        this.$forceUpdate()
      })
    },
  },
  watch: {
    posts(oldPosts, newPosts) {
      this.hasPosts = Boolean(this.posts.length)
      console.log(this.hasPosts, this.posts)
      this.$forceUpdate()
    },
  },
  async mounted() {
    this.getRecentPosts()
  },
}
</script>

<style>
.hide-el {
  display: none!important;
}
.iframetv2 {
  width: 100%;
  height: 50vh;
}
.fullheight {
  height: 100vh;
}
.split35 {
  height: 35vh;
  width: 100%;
  background-color: #000000;
}
.split50 {
  height: 50vh;
  width: 100%;
  background-color: #000000;
}
.split10 {
  height: 10vh;
  width: 100%;
  background: #000000;
}
.dark-bg {
  background: #000000;
}
.center-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-img img{
  width: 500px;
  height: calc(500/3.49)px;
}
.dark-card {
  background: #171717;
  border-color: #171717;
  border-radius: 16px;
}
.dark-card {
  color: #ccc
}
.company-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.company-logo img{
  width: 200px;
  height: calc(200px/3.49)px;
}
</style>
